import React from "react"
import Helmet from "react-helmet"
import Unsubscribe from "@tightrope/footerlinks/unsubscribe"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UnsubscribePage() {
  return(
    <HomepageLayout>
        <Helmet data={JSONData}><title>Unsubscribe - </title>
        <style type="text/css">{`
        footer {
          position: relative;
          width: 100%;
          bottom: 2px;
          padding: 0;
          margin:0;
          z-index: 1;
          border-top:1px solid #ccc;
        }
        footer ul {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap:wrap;
          color: #666;
          font-size: 12px;
          padding: 16px 0 0;
          list-style:none;
        }
        footer ul li {
          margin: 0;
        }
        footer ul li a {
          text-decoration: none;
          color: #666;
        }
        footer ul li:after {
          content: '|';
          padding: 0 4px;
        }
        footer ul li:last-child:after {
          content: none;
          padding: 0;
        }
        `}</style>
        </Helmet>
    <Unsubscribe></Unsubscribe>
    </HomepageLayout>
  )
}
